
/* eslint-disable */

import { ref, reactive, onMounted, provide, computed, watch } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";

export default defineComponent({
  props: {
    auth: {}
  },
  components: {},
  setup(props) {
    onMounted(() => {});

    watch(
      () => props.auth,
      async (value: any) => {
        if (value) {
          if (value.isBuyer == false) {
            cash(window).ready(() => {
              cash("#toUser-modal").modal("show");
            });
          }
        }
      },
      { immediate: true }
    );

    return {};
  },
  methods: {}
});
