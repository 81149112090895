
/* eslint-disable */

import { ref, reactive, onMounted, provide, computed } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import ToUser from "@/components/user-modal/Main.vue";
import router from "@/router";
import CompanyEdit from "@/views/company-edit/Main.vue";
import CompanyApply from "@/views/company-apply/Main.vue";

export default defineComponent({
  components: {
    ToUser,
    CompanyEdit,
    CompanyApply
  },
  setup() {
    const model = CloudFun.current?.model;
    const info = ref<any>({});
    const vendor = ref<any>({});
    const canEdit = ref(false);

    const getInfo = () => {
      return model
        ?.dispatch("user/find")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getVendor = () => {
      return model
        ?.dispatch("member/vendor")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };
    const auth = ref<any>({});

    const getAuth = () => {
      return model
        ?.dispatch("user/auth")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const init = async () => {
      auth.value = await getAuth();
      if (!auth.value.isBuyer) router.push("/company-not-vendor");
      info.value = await getInfo();

      console.log("init value", auth.value);

      canEdit.value = auth.value.position === 1;

      if (info.value.isVendor) {
        vendor.value = await getVendor();
      } else {
        setPage("apply");
      }
    };

    onMounted(async () => {
      await init();
    });

    const showPage = ref("info");
    const setPage = async (name: string) => {
      if (name == "info") await init();
      showPage.value = name;
    };

    return {
      info,
      vendor,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`,
      auth,
      setPage,
      showPage,
      canEdit
    };
  },
  methods: {}
});
