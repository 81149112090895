
/* eslint-disable */
import { ref, reactive, onMounted, provide, computed } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import router from "@/router";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import InputBox, { InputBoxOptions } from "@/cloudfun/components/InputBox.vue";
import CfInputBox from "@/cloudfun/components/CfInputBoxHorizontal.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";

export default defineComponent({
  components: {
    // Grid,
    InputBox,
    FileUploader,
    CfInputBox,
    VForm: Form
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const imageUrl = ref<any>({});
    const uploader = ref<any>({});
    const uploadAction = `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`;
    const user = JSON.parse(sessionStorage.getItem("EmptyNext-user")!);
    const headers = {
      Authorization: `Bearer ${user?.token}`
    };
    const imageData = { ProductID: 0 };

    const applyModal = reactive({
      formData: <any>{
        // 	 "taxID": "string",
        //   "name": "string",
        //   "name_Eng": "string",
        //   "address": "string",
        //   "address_Eng": "string",
        //   "setup_Date": "string",
        //   "capital_Stock_Amount": "string",
        //   "paid_In_Capital_Amount": "string",
        //   "contact_Email": "string",
        //   "contact_Tel": "string",
        //   "contact_Title": "string",
        //   "position": 0
      },
      async sendApply() {
        applyModal.formData.position = auth.value.position;
        //applyModal.formData.setup_Date = applyModal.formData.setup_Date.replaceAll('-', '/');
        await model
          ?.dispatch("vendor/apply", applyModal.formData)
          .then(response => {
            if (response.status) {
              CloudFun.send("info", {
                subject: "已送出申請",
                content: "我們將盡快進行審核"
              });
            } else {
              CloudFun.send("error", {
                subject: "申請失敗",
                content: response.message
              });
            }
          })
          .catch(error => {
            CloudFun.send("error", {
              subject: "申請失敗",
              content: error.message
            });
          });
        status.value = await getStatus();
      },
      async formValid() {}
    });

    const servicesSelectorOptions: InputBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "請輸入服務類別",
      textField: "name",
      valueField: "id",
      addIfNotExists: true,
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            resolve(applyModal.formData.service_Category);
          }), // eslint-disable-line
        query: params => new Promise((resolve, reject) => {}),
        insert: value => {
          var data = value.name;
          return new Promise((resolve, reject) => {
            resolve({ id: data, name: data });
          });
        }
      }
    };

    const categorySelectorOptions: InputBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "請輸入產品分類",
      textField: "name",
      valueField: "id",
      addIfNotExists: true,
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            resolve(applyModal.formData.product_Category);
          }), // eslint-disable-line
        query: params => new Promise((resolve, reject) => {}),
        insert: value => {
          var data = value.name;
          return new Promise((resolve, reject) => {
            resolve({ id: data, name: data });
          });
        }
      }
    };

    const overseaLocationsSelectorOptions: InputBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "請輸入海外據點",
      textField: "name",
      valueField: "id",
      addIfNotExists: true,
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            resolve(applyModal.formData.oversea_Locations);
          }), // eslint-disable-line
        query: params => new Promise((resolve, reject) => {}),
        insert: value => {
          var data = value.name;
          return new Promise((resolve, reject) => {
            resolve({ id: data, name: data });
          });
        }
      }
    };

    const overseaMarketSelectorOptions: InputBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "請輸入海外市場",
      textField: "name",
      valueField: "id",
      addIfNotExists: true,
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            resolve(applyModal.formData.oversea_Market);
          }), // eslint-disable-line
        query: params => new Promise((resolve, reject) => {}),
        insert: value => {
          var data = value.name;
          return new Promise((resolve, reject) => {
            resolve({ id: data, name: data });
          });
        }
      }
    };

    const targetMarketSelectorOptions: InputBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "請輸入目標市場地區",
      textField: "name",
      valueField: "id",
      addIfNotExists: true,
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            resolve(applyModal.formData.target_Market);
          }), // eslint-disable-line
        query: params => new Promise((resolve, reject) => {}),
        insert: value => {
          var data = value.name;
          return new Promise((resolve, reject) => {
            resolve({ id: data, name: data });
          });
        }
      }
    };

    const getAuth = () => {
      return model
        ?.dispatch("user/auth")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getStatus = () => {
      return model
        ?.dispatch("vendor/status")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const auth = ref<any>({});
    const status = ref<any>({});

    onMounted(async () => {
      auth.value = await getAuth();
      status.value = await getStatus();
    });

    // const phoneExp = /^09[0-9]{8}$/;
    const phoneExp = /(\d{2,3}-?|\(\d{2,3}\))\d{3,4}-?\d{4}|09\d{2}(\d{6}|-\d{3}-\d{3})/;

    const validationSchema = Yup.object().shape({
      name: Yup.string().required("必要欄位"),
      name_Eng: Yup.string().required("必要欄位"),
      address_Eng: Yup.string().required("必要欄位"),
      address: Yup.string().required("必要欄位"),
      setup_Date: Yup.string().required("必要欄位"),
      taxID: Yup.string()
        .length(8, "統一編號格式錯誤")
        .required("必要欄位"),
      capital_Stock_Amount: Yup.string().required("必要欄位"),
      paid_In_Capital_Amount: Yup.string().required("必要欄位"),
      contact_Title: Yup.string().required("必要欄位"),
      contact_Tel: Yup.string()
        .matches(phoneExp, "業務電話格式錯誤")
        .required("必要欄位"),
      contact_Email: Yup.string()
        .email("業務信箱格式錯誤")
        .required("必要欄位")
    });

    return {
      status,
      categorySelectorOptions,
      servicesSelectorOptions,
      applyModal,
      uploader,
      uploadAction,
      headers,
      imageData,
      imageUrl,
      targetMarketSelectorOptions,
      overseaMarketSelectorOptions,
      overseaLocationsSelectorOptions,
      validationSchema
    };
  },
  methods: {
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    }
  }
});
