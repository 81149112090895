<template>
  <div>
    <v-form
      :validation-schema="validationSchema"
      :initial-values="vendorModal"
      @submit="vendorModal.sendSave"
    >
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">供應商企業資料</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <button
            @click="close"
            type="button"
            class="button text-black bg-gray-500 shadow-md mr-2 inline-flex items-center"
          >
            <XIcon class="mr-2 w-4 h-4" />
            取消
          </button>
          <button
            type="submit"
            class="button text-white bg-theme-1 shadow-md mr-2 inline-flex items-center"
          >
            <CheckIcon class="mr-2 w-4 h-4" />
            儲存
          </button>
        </div>
      </div>
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-10 mt-10">
        <div class=" text-lg text-gray-600 mb-10">公司基本資料</div>
        <div class=" lg:grid grid-cols-2 gap-5">
          <div class="">
            <div class=" sm:flex mb-8">
              <CfInputBox
                label="公司名稱"
                name="name"
                placeholder="公司名稱"
                type="text"
                :showMessage="false"
                :disabled="false"
                :modelValue="vendorModal.formData.name"
                @update:modelValue="
                  val => {
                    vendorModal.formData.name = val;
                  }
                "
              ></CfInputBox>
            </div>
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                公司名稱(英)
              </div>
              <div class=" w-full ">
                <input
                  type="text"
                  class=" w-full intro-x input input--lg border border-gray-300 block mr-3"
                  v-model="vendorModal.formData.name_Eng"
                />
              </div>
            </div>
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                成立時間
              </div>
              <div class=" w-full ">
                <input
                  :disabled="false"
                  type="text"
                  placeholder="民國年月日(ex.1110101)"
                  class=" w-full intro-x input input--lg border border-gray-300 block mr-3"
                  v-model="vendorModal.formData.setup_Date"
                />
                <!-- <input 
								type="date" 
								class=" w-full intro-x input input--lg border border-gray-300 block mr-3" 
								v-model="vendorModal.formData.setup_Date" 
							/> -->
                <!-- <Litepicker 
								v-model="vendorModal.formData.setup_Date"
								:options="{
									autoApply: false,
									showWeekNumbers: true,
									dropdowns: {
										minYear: 1990,
										maxYear: null,
										months: true,
										years: true
									}
								}" class="w-full intro-x input input--lg border border-gray-300 block mr-3" 
							/> -->
              </div>
            </div>
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                登記地址
              </div>
              <div class=" w-full ">
                <input
                  :disabled="false"
                  type="text"
                  class=" w-full intro-x input input--lg border border-gray-300 block mr-3"
                  v-model="vendorModal.formData.address"
                />
              </div>
            </div>
          </div>
          <div class="">
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                統一編號
              </div>
              <div class=" w-full ">
                <input
                  :disabled="false"
                  type="text"
                  class=" w-full intro-x input input--lg border border-gray-300 block mr-3"
                  v-model="vendorModal.formData.taxID"
                />
              </div>
            </div>
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                資本額
              </div>
              <div class=" w-full ">
                <input
                  :disabled="false"
                  type="text"
                  class=" w-full intro-x input input--lg border border-gray-300 block mr-3"
                  v-model="vendorModal.formData.capital_Stock_Amount"
                />
              </div>
            </div>
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                實收資本額
              </div>
              <div class=" w-full ">
                <input
                  :disabled="false"
                  type="text"
                  class=" w-full intro-x input input--lg border border-gray-300 block mr-3"
                  v-model="vendorModal.formData.paid_In_Capital_Amount"
                />
              </div>
            </div>
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                英文地址
              </div>
              <div class=" w-full ">
                <input
                  type="text"
                  class=" w-full intro-x input input--lg border border-gray-300 block mr-3"
                  v-model="vendorModal.formData.address_Eng"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="intro-y box p-10 mt-10">
        <div class=" text-lg text-gray-600 mb-10">公司介紹</div>
        <div class="">
          <div class=" sm:flex mb-8">
            <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
              識別標誌
            </div>
            <div class=" w-full">
              <file-uploader
                :ref="el => (uploader = el)"
                :headers="headers"
                :data="imageData"
                id="user-photo-uri"
                style="min-width: 200px; max-width: 200px; min-height: 200px; max-height: 200px;"
                class="mr-10"
                mode="image"
                :modelValue="imageUrl"
                :action="uploadAction"
                :autoUpload="true"
                :limitedWidth="200"
                :limitedHeight="200"
                @update:modelValue="
                  value => {
                    if (value?.archiveID) {
                      vendorModal.formData.logo_ArchiveID = value?.archiveID;
                    } else if (value == 'delete')
                      vendorModal.formData.logo_ArchiveID = '';
                  }
                "
                @filter="uploaderFilter"
              />
            </div>
          </div>
          <div class=" sm:flex mb-8">
            <!-- <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
            公司形象
          </div>
          <div class=" w-full ">
            <input
              type="text"
              class=" w-full intro-x input input--lg border border-gray-300 block mr-3"
              v-model="vendorModal.formData.profile"
            />
          </div> -->
            <CfInputBox
              label="形象影片"
              name="profile"
              placeholder="僅支援 Youtube 影片網址"
              type="text"
              :isRequired="true"
              :modelValue="vendorModal.formData.profile"
              @update:modelValue="
                val => {
                  vendorModal.formData.profile = val;
                }
              "
            ></CfInputBox>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
              詳細介紹
            </div>
            <div class="w-full ">
              <textarea
                v-model="vendorModal.formData.description"
                class="input w-full border mt-2"
                placeholder="詳細介紹"
              >
              </textarea>
            </div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
              介紹(英)
            </div>
            <div class=" w-full ">
              <textarea
                class="input w-full border mt-2"
                v-model="vendorModal.formData.description_Eng"
              >
				公司介紹(英文)
			</textarea
              >
            </div>
          </div>
        </div>
        <div class=" lg:grid grid-cols-2 gap-5">
          <div class="">
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                官方網站
              </div>
              <div class=" w-full ">
                <input
                  type="text"
                  class=" w-full intro-x input input--lg border border-gray-300 block mr-3"
                  v-model="vendorModal.formData.website"
                />
              </div>
            </div>
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                客服信箱
              </div>
              <div class=" w-full">
                <input
                  type="email"
                  class=" w-full intro-x input input--lg border border-gray-300 block mr-3"
                  v-model="vendorModal.formData.customerService_Mail"
                />
              </div>
            </div>
          </div>
          <div class="">
            <div class=" sm:flex mb-8">
              <!-- <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
              公司電話
            </div>
            <div class=" w-full ">
              <input
                type="text"
                class=" w-full intro-x input input--lg border border-gray-300 block mr-3"
                v-model="vendorModal.formData.tel"
              />
            </div> -->
              <CfInputBox
                label="公司電話"
                name="tel"
                placeholder="公司電話"
                type="text"
                :isRequired="true"
                :modelValue="vendorModal.formData.tel"
                @update:modelValue="
                  val => {
                    vendorModal.formData.tel = val;
                  }
                "
              ></CfInputBox>
            </div>
            <div class=" sm:flex mb-8">
              <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
                社群媒體
              </div>
              <div class=" w-full ">
                <input
                  type="text"
                  class=" w-full intro-x input input--lg border border-gray-300 block mr-3"
                  v-model="vendorModal.formData.socialMedia"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <!--<div class=" sm:flex mb-8">
					<div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">業務信箱</div>
					<div class=" w-full">
						<input 
							type="email" 
							class=" w-full intro-x input input--lg border border-gray-300 block mr-3" 
							v-model="vendorModal.formData.customerService_Mail"
						/>
					</div>
				</div> -->

          <div class=" sm:flex mb-8">
            <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
              海外據點
            </div>
            <div class=" w-full ">
              <input-box
                v-if="vendorModal?.formData?.oversea_Locations"
                v-bind="overseaLocationsSelectorOptions"
                v-model="vendorModal.formData.oversea_Locations"
              />
            </div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
              海外市場
            </div>
            <div class=" w-full ">
              <input-box
                v-if="vendorModal?.formData?.oversea_Market"
                v-bind="overseaMarketSelectorOptions"
                v-model="vendorModal.formData.oversea_Market"
              />
            </div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
              目標市場地區
            </div>
            <div class=" w-full ">
              <input-box
                v-if="vendorModal?.formData?.target_Market"
                v-bind="targetMarketSelectorOptions"
                v-model="vendorModal.formData.target_Market"
              />
            </div>
          </div>
          <!-- <div class=" sm:flex mb-8">
            <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
              產品分類
            </div>
            <div class=" w-full">
              <input-box
                v-if="vendorModal?.formData?.product_Category"
                v-bind="categorySelectorOptions"
                v-model="vendorModal.formData.product_Category"
              />
            </div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-32 min-w-32 sm:text-right text-base mr-5">
              服務分類
            </div>
            <div class=" w-full ">
              <input-box
                v-if="vendorModal?.formData?.service_Category"
                v-bind="servicesSelectorOptions"
                v-model="vendorModal.formData.service_Category"
              />
            </div>
          </div> -->
        </div>
      </div>
    </v-form>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import { ref, reactive, onMounted, provide, computed } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import router from "@/router";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import InputBox, { InputBoxOptions } from "@/cloudfun/components/InputBox.vue";
import CfInputBox from "@/cloudfun/components/CfInputBoxHorizontal.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";

export default defineComponent({
  components: {
    // Grid,
    InputBox,
    FileUploader,
    CfInputBox,
    VForm: Form
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const imageUrl = ref<any>({});
    const uploader = ref<any>({});
    const uploadAction = `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`;
    const user = JSON.parse(sessionStorage.getItem("EmptyNext-user")!);
    const headers = {
      Authorization: `Bearer ${user?.token}`
    };
    const imageData = { ProductID: 0 };

    const vendorModal = reactive({
      formData: <any>{
        // 	 "taxID": "string",
        //   "name": "string",
        //   "name_Eng": "string",
        //   "address": "string",
        //   "address_Eng": "string",
        //   "setup_Date": "string",
        //   "capital_Stock_Amount": "string",
        //   "paid_In_Capital_Amount": "string",
        //   "contact_Email": "string",
        //   "contact_Tel": "string",
        //   "contact_Title": "string",
        //   "position": 0
      },
      async sendSave() {
        debugger;
        await await model
          ?.dispatch("vendor/save", vendorModal.formData)
          .then(response => {
            if (response.status) {
              CloudFun.send("info", {
                subject: "儲存完成",
                content: "修改成功"
              });
              //   setTimeout(() => {
              //     router.push("/company");
              //   }, 1500);
              close();
            } else {
              CloudFun.send("error", {
                subject: "儲存失敗",
                content: response.message
              });
            }
          })
          .catch(error => {
            CloudFun.send("error", {
              subject: "儲存失敗",
              content: error.message
            });
          });
      },
      async formValid() {}
    });

    const service_Category = ref<any>([]);

    const servicesSelectorOptions: InputBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "請輸入服務類別",
      textField: "name",
      valueField: "id",
      addIfNotExists: true,
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            resolve(service_Category.value);
          }), // eslint-disable-line
        query: params => new Promise((resolve, reject) => {}),
        insert: value => {
          var data = value.name;
          return new Promise((resolve, reject) => {
            resolve({ id: data, name: data });
          });
        }
      }
    };

    const categorySelectorOptions: InputBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "請輸入產品分類",
      textField: "name",
      valueField: "id",
      addIfNotExists: true,
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            resolve(vendorModal.formData.product_Category);
          }), // eslint-disable-line
        query: params => new Promise((resolve, reject) => {}),
        insert: value => {
          var data = value.name;
          return new Promise((resolve, reject) => {
            resolve({ id: data, name: data });
          });
        }
      }
    };

    const overseaLocationsSelectorOptions: InputBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "請輸入海外據點",
      textField: "name",
      valueField: "id",
      addIfNotExists: true,
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            resolve(vendorModal.formData.oversea_Locations);
          }), // eslint-disable-line
        query: params => new Promise((resolve, reject) => {}),
        insert: value => {
          var data = value.name;
          return new Promise((resolve, reject) => {
            resolve({ id: data, name: data });
          });
        }
      }
    };

    const overseaMarketSelectorOptions: InputBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "請輸入海外市場",
      textField: "name",
      valueField: "id",
      addIfNotExists: true,
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            resolve(vendorModal.formData.oversea_Market);
          }), // eslint-disable-line
        query: params => new Promise((resolve, reject) => {}),
        insert: value => {
          var data = value.name;
          return new Promise((resolve, reject) => {
            resolve({ id: data, name: data });
          });
        }
      }
    };

    const targetMarketSelectorOptions: InputBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "請輸入目標市場地區",
      textField: "name",
      valueField: "id",
      addIfNotExists: true,
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            resolve(vendorModal.formData.target_Market);
          }), // eslint-disable-line
        query: params => new Promise((resolve, reject) => {}),
        insert: value => {
          var data = value.name;
          return new Promise((resolve, reject) => {
            resolve({ id: data, name: data });
          });
        }
      }
    };

    const getAuth = () => {
      return model
        ?.dispatch("user/auth")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getVendor = () => {
      return model
        ?.dispatch("member/vendor")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const auth = ref<any>({});
    const vendor = ref<any>({});

    onMounted(async () => {
      auth.value = await getAuth();
      if (!auth.value.isBuyer) router.push("/");
      if (auth.value.isVendor) {
        vendor.value = await getVendor();
        if (vendor.value) {
          Object.assign(vendorModal.formData, vendor.value);

          if (vendor.value.logo_ArchiveID)
            imageUrl.value = `${uploadAction}/${vendor.value.logo_ArchiveID}`;
          if (vendor.value.service_Category)
            service_Category.value = vendor.value.service_Category;
        }
      } else {
        router.push("/");
      }
    });

    const phoneExp = new RegExp(
      "^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$"
    );

    const validationSchema = Yup.object().shape({
      // profile: Yup.string().required("必要欄位"),
      // description: Yup.string().required("必要欄位"),
      // tel: Yup.string().matches(phoneExp, '公司電話格式錯誤').required("必要欄位"),
    });

    const close = () => {
      emit("setPage", "info");
    };

    return {
      categorySelectorOptions,
      servicesSelectorOptions,
      vendorModal,
      uploader,
      uploadAction,
      headers,
      imageData,
      imageUrl,
      targetMarketSelectorOptions,
      overseaMarketSelectorOptions,
      overseaLocationsSelectorOptions,
      validationSchema,
      close
    };
  },
  methods: {
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    }
  }
});
</script>

<style lang="scss">
.min-w-32 {
  min-width: 8rem;
}
</style>
