<template>
  <!-- 前往升級買家帳號modal -->
  <div id="toUser-modal" class="modal flex justify-center items-center">
    <div
      class="modal__content max-h-screen overflow-y-scroll sm:overflow-y-visible relative w-screen p-6 max-w-screen-lg"
    >
      <!-- <a data-dismiss="modal" href="javascript:;" class="absolute right-0 top-0 mt-6 mr-6">
        <xIcon class="w-8 h-8 text-gray-500" />
      </a> -->
      <div class="">
        <div class="font-bold text-2xl xl:text-3xl my-3">Oops!</div>
        <div class="py-12 px-0">
          <div class=" text-base mb-10">
            您目前的帳號為「一般帳號」，無法使用此功能。<br />
            請升級為<b>買家帳號</b>，享受更完整的服務。
          </div>
          <a
            href="user"
            data-toggle="modal"
            data-target="#company-modal"
            class="button button--lg text-white bg-theme-1 shadow-md mr-2"
          >
            前往升級
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable */

import { ref, reactive, onMounted, provide, computed, watch } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";

export default defineComponent({
  props: {
    auth: {}
  },
  components: {},
  setup(props) {
    onMounted(() => {});

    watch(
      () => props.auth,
      async (value: any) => {
        if (value) {
          if (value.isBuyer == false) {
            cash(window).ready(() => {
              cash("#toUser-modal").modal("show");
            });
          }
        }
      },
      { immediate: true }
    );

    return {};
  },
  methods: {}
});
</script>
