<template>
  <div v-if="!status.isApplying">
    <v-form
      :validation-schema="validationSchema"
      @submit="applyModal.sendApply"
    >
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">供應商申請資料</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <button
            type="submit"
            class="button text-white bg-theme-1 shadow-md mr-2 inline-flex items-center"
          >
            <CheckIcon class="mr-2 w-4 h-4" />
            送出申請
          </button>
        </div>
      </div>
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-10 mt-10">
        <div class=" text-lg text-gray-600 mb-10">公司基本資料</div>
        <div class=" lg:grid grid-cols-2 gap-5">
          <div class="">
            <div class=" sm:flex mb-8">
              <!-- <div class=" sm:w-32 sm:text-right text-base mr-5">公司名稱</div>
						<div class=" w-full">
							<input 
								type="text" 
								class=" w-full intro-x input input--lg border border-gray-300 block mr-3" 
								v-model="applyModal.formData.name" 
							/>
						</div> -->
              <CfInputBox
                label="公司名稱"
                name="name"
                placeholder="公司名稱"
                type="text"
                :isRequired="true"
                :modelValue="applyModal.formData.name"
                @update:modelValue="
                  val => {
                    applyModal.formData.name = val;
                  }
                "
              ></CfInputBox>
            </div>
            <div class=" sm:flex mb-8">
              <!-- <div class=" sm:w-32 sm:text-right text-base mr-5">公司名稱(英)</div>
						<div class=" w-full ">
							<input 
								type="text" 
								class=" w-full intro-x input input--lg border border-gray-300 block mr-3" 
								v-model="applyModal.formData.name_Eng" 
							/>
						</div> -->
              <CfInputBox
                label="公司名稱(英)"
                name="name_Eng"
                placeholder="公司名稱(英)"
                type="text"
                :isRequired="true"
                :modelValue="applyModal.formData.name_Eng"
                @update:modelValue="
                  val => {
                    applyModal.formData.name_Eng = val;
                  }
                "
              ></CfInputBox>
            </div>
            <div class=" sm:flex mb-8">
              <!-- <div class=" sm:w-32 sm:text-right text-base mr-5">成立時間</div>
						<div class=" w-full ">
							<input 
								type="text" 
								placeholder="民國年月日(ex.1110101)"
								class=" w-full intro-x input input--lg border border-gray-300 block mr-3" 
								v-model="applyModal.formData.setup_Date" 
							/>
						</div> -->
              <CfInputBox
                label="成立時間"
                name="setup_Date"
                placeholder="民國年月日(ex.1110101)"
                type="text"
                :isRequired="true"
                :modelValue="applyModal.formData.setup_Date"
                @update:modelValue="
                  val => {
                    applyModal.formData.setup_Date = val;
                  }
                "
              ></CfInputBox>
            </div>
            <div class=" sm:flex mb-8">
              <!-- <div class=" sm:w-32 sm:text-right text-base mr-5">登記地址</div>
						<div class=" w-full ">
							<input 
								type="text" 
								class=" w-full intro-x input input--lg border border-gray-300 block mr-3" 
								v-model="applyModal.formData.address" 
							/>
						</div> -->
              <CfInputBox
                label="登記地址"
                name="address"
                placeholder="登記地址"
                type="text"
                :isRequired="true"
                :modelValue="applyModal.formData.address"
                @update:modelValue="
                  val => {
                    applyModal.formData.address = val;
                  }
                "
              ></CfInputBox>
            </div>
          </div>
          <div class="">
            <div class=" sm:flex mb-8">
              <!-- <div class=" sm:w-32 sm:text-right text-base mr-5">統一編號</div>
						<div class=" w-full ">
							<input 
								type="text" 
								class=" w-full intro-x input input--lg border border-gray-300 block mr-3" 
								v-model="applyModal.formData.taxID" 
							/>
						</div> -->
              <CfInputBox
                label="統一編號"
                name="taxID"
                placeholder="統一編號"
                type="text"
                :isRequired="true"
                :modelValue="applyModal.formData.taxID"
                @update:modelValue="
                  val => {
                    applyModal.formData.taxID = val;
                  }
                "
              ></CfInputBox>
            </div>
            <div class=" sm:flex mb-8">
              <!-- <div class=" sm:w-32 sm:text-right text-base mr-5">資本額</div>
						<div class=" w-full ">
							<input 
								type="text" 
								class=" w-full intro-x input input--lg border border-gray-300 block mr-3" 
								v-model="applyModal.formData.capital_Stock_Amount" 
							/>
						</div> -->
              <CfInputBox
                label="資本額"
                name="capital_Stock_Amount"
                placeholder="資本額"
                type="text"
                :isRequired="true"
                :modelValue="applyModal.formData.capital_Stock_Amount"
                @update:modelValue="
                  val => {
                    applyModal.formData.capital_Stock_Amount = val;
                  }
                "
              ></CfInputBox>
            </div>
            <div class=" sm:flex mb-8">
              <!-- <div class=" sm:w-32 sm:text-right text-base mr-5">實收資本額</div>
						<div class=" w-full ">
							<input 
								type="text" 
								class=" w-full intro-x input input--lg border border-gray-300 block mr-3" 
								v-model="applyModal.formData.paid_In_Capital_Amount"
							/>
						</div> -->
              <CfInputBox
                label="實收資本額"
                name="paid_In_Capital_Amount"
                placeholder="實收資本額"
                type="text"
                :isRequired="true"
                :modelValue="applyModal.formData.paid_In_Capital_Amount"
                @update:modelValue="
                  val => {
                    applyModal.formData.paid_In_Capital_Amount = val;
                  }
                "
              ></CfInputBox>
            </div>
            <div class=" sm:flex mb-8">
              <!-- <div class=" sm:w-32 sm:text-right text-base mr-5">英文地址</div>
						<div class=" w-full ">
							<input 
								type="text" 
								class=" w-full intro-x input input--lg border border-gray-300 block mr-3" 
								v-model="applyModal.formData.address_Eng" 
							/>
						</div> -->
              <CfInputBox
                label="英文地址"
                name="address_Eng"
                placeholder="英文地址"
                type="text"
                :isRequired="true"
                :modelValue="applyModal.formData.address_Eng"
                @update:modelValue="
                  val => {
                    applyModal.formData.address_Eng = val;
                  }
                "
              ></CfInputBox>
            </div>
          </div>
        </div>
      </div>

      <div class="intro-y box p-10 mt-10">
        <div class=" text-lg text-gray-600 mb-10">個人資料</div>
        <div class=" lg:grid grid-cols-2 gap-5">
          <div class="">
            <div class=" sm:flex mb-8">
              <!-- <div class=" sm:w-32 sm:text-right text-base mr-5">職稱</div>
						<div class=" w-full">
							<input 
								type="text" 
								class=" w-full intro-x input input--lg border border-gray-300 block mr-3" 
								v-model="applyModal.formData.contact_Title" 
							/>
						</div> -->
              <CfInputBox
                label="職稱"
                name="contact_Title"
                placeholder="職稱"
                type="text"
                :isRequired="true"
                :modelValue="applyModal.formData.contact_Title"
                @update:modelValue="
                  val => {
                    applyModal.formData.contact_Title = val;
                  }
                "
              ></CfInputBox>
            </div>
            <div class=" sm:flex mb-8">
              <!-- <div class=" sm:w-32 sm:text-right text-base mr-5">業務電話</div>
						<div class=" w-full ">
							<input 
								type="text" 
								class=" w-full intro-x input input--lg border border-gray-300 block mr-3" 
								v-model="applyModal.formData.contact_Tel" 
							/>
						</div> -->
              <CfInputBox
                label="業務電話"
                name="contact_Tel"
                placeholder="業務電話"
                type="text"
                :isRequired="true"
                :modelValue="applyModal.formData.contact_Tel"
                @update:modelValue="
                  val => {
                    applyModal.formData.contact_Tel = val;
                  }
                "
              ></CfInputBox>
            </div>
          </div>
          <div class="">
            <div class=" sm:flex mb-8">
              <!-- <div class=" sm:w-32 sm:text-right text-base mr-5">業務信箱</div>
						<div class=" w-full ">
							<input 
								type="text" 
								class=" w-full intro-x input input--lg border border-gray-300 block mr-3" 
								v-model="applyModal.formData.contact_Email" 
							/>
						</div> -->
              <CfInputBox
                label="業務信箱"
                name="contact_Email"
                placeholder="業務信箱"
                type="text"
                :isRequired="true"
                :modelValue="applyModal.formData.contact_Email"
                @update:modelValue="
                  val => {
                    applyModal.formData.contact_Email = val;
                  }
                "
              ></CfInputBox>
            </div>
          </div>
        </div>
      </div>
    </v-form>
    <!-- END: HTML Table Data -->
  </div>
  <div v-else>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-10 mt-10">
      <div class=" text-lg text-gray-800 mb-2">申請狀態：資料審核中</div>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import { ref, reactive, onMounted, provide, computed } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import router from "@/router";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import InputBox, { InputBoxOptions } from "@/cloudfun/components/InputBox.vue";
import CfInputBox from "@/cloudfun/components/CfInputBoxHorizontal.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";

export default defineComponent({
  components: {
    // Grid,
    InputBox,
    FileUploader,
    CfInputBox,
    VForm: Form
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const imageUrl = ref<any>({});
    const uploader = ref<any>({});
    const uploadAction = `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`;
    const user = JSON.parse(sessionStorage.getItem("EmptyNext-user")!);
    const headers = {
      Authorization: `Bearer ${user?.token}`
    };
    const imageData = { ProductID: 0 };

    const applyModal = reactive({
      formData: <any>{
        // 	 "taxID": "string",
        //   "name": "string",
        //   "name_Eng": "string",
        //   "address": "string",
        //   "address_Eng": "string",
        //   "setup_Date": "string",
        //   "capital_Stock_Amount": "string",
        //   "paid_In_Capital_Amount": "string",
        //   "contact_Email": "string",
        //   "contact_Tel": "string",
        //   "contact_Title": "string",
        //   "position": 0
      },
      async sendApply() {
        applyModal.formData.position = auth.value.position;
        //applyModal.formData.setup_Date = applyModal.formData.setup_Date.replaceAll('-', '/');
        await model
          ?.dispatch("vendor/apply", applyModal.formData)
          .then(response => {
            if (response.status) {
              CloudFun.send("info", {
                subject: "已送出申請",
                content: "我們將盡快進行審核"
              });
            } else {
              CloudFun.send("error", {
                subject: "申請失敗",
                content: response.message
              });
            }
          })
          .catch(error => {
            CloudFun.send("error", {
              subject: "申請失敗",
              content: error.message
            });
          });
        status.value = await getStatus();
      },
      async formValid() {}
    });

    const servicesSelectorOptions: InputBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "請輸入服務類別",
      textField: "name",
      valueField: "id",
      addIfNotExists: true,
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            resolve(applyModal.formData.service_Category);
          }), // eslint-disable-line
        query: params => new Promise((resolve, reject) => {}),
        insert: value => {
          var data = value.name;
          return new Promise((resolve, reject) => {
            resolve({ id: data, name: data });
          });
        }
      }
    };

    const categorySelectorOptions: InputBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "請輸入產品分類",
      textField: "name",
      valueField: "id",
      addIfNotExists: true,
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            resolve(applyModal.formData.product_Category);
          }), // eslint-disable-line
        query: params => new Promise((resolve, reject) => {}),
        insert: value => {
          var data = value.name;
          return new Promise((resolve, reject) => {
            resolve({ id: data, name: data });
          });
        }
      }
    };

    const overseaLocationsSelectorOptions: InputBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "請輸入海外據點",
      textField: "name",
      valueField: "id",
      addIfNotExists: true,
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            resolve(applyModal.formData.oversea_Locations);
          }), // eslint-disable-line
        query: params => new Promise((resolve, reject) => {}),
        insert: value => {
          var data = value.name;
          return new Promise((resolve, reject) => {
            resolve({ id: data, name: data });
          });
        }
      }
    };

    const overseaMarketSelectorOptions: InputBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "請輸入海外市場",
      textField: "name",
      valueField: "id",
      addIfNotExists: true,
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            resolve(applyModal.formData.oversea_Market);
          }), // eslint-disable-line
        query: params => new Promise((resolve, reject) => {}),
        insert: value => {
          var data = value.name;
          return new Promise((resolve, reject) => {
            resolve({ id: data, name: data });
          });
        }
      }
    };

    const targetMarketSelectorOptions: InputBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "請輸入目標市場地區",
      textField: "name",
      valueField: "id",
      addIfNotExists: true,
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            resolve(applyModal.formData.target_Market);
          }), // eslint-disable-line
        query: params => new Promise((resolve, reject) => {}),
        insert: value => {
          var data = value.name;
          return new Promise((resolve, reject) => {
            resolve({ id: data, name: data });
          });
        }
      }
    };

    const getAuth = () => {
      return model
        ?.dispatch("user/auth")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getStatus = () => {
      return model
        ?.dispatch("vendor/status")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const auth = ref<any>({});
    const status = ref<any>({});

    onMounted(async () => {
      auth.value = await getAuth();
      status.value = await getStatus();
    });

    // const phoneExp = /^09[0-9]{8}$/;
    const phoneExp = /(\d{2,3}-?|\(\d{2,3}\))\d{3,4}-?\d{4}|09\d{2}(\d{6}|-\d{3}-\d{3})/;

    const validationSchema = Yup.object().shape({
      name: Yup.string().required("必要欄位"),
      name_Eng: Yup.string().required("必要欄位"),
      address_Eng: Yup.string().required("必要欄位"),
      address: Yup.string().required("必要欄位"),
      setup_Date: Yup.string().required("必要欄位"),
      taxID: Yup.string()
        .length(8, "統一編號格式錯誤")
        .required("必要欄位"),
      capital_Stock_Amount: Yup.string().required("必要欄位"),
      paid_In_Capital_Amount: Yup.string().required("必要欄位"),
      contact_Title: Yup.string().required("必要欄位"),
      contact_Tel: Yup.string()
        .matches(phoneExp, "業務電話格式錯誤")
        .required("必要欄位"),
      contact_Email: Yup.string()
        .email("業務信箱格式錯誤")
        .required("必要欄位")
    });

    return {
      status,
      categorySelectorOptions,
      servicesSelectorOptions,
      applyModal,
      uploader,
      uploadAction,
      headers,
      imageData,
      imageUrl,
      targetMarketSelectorOptions,
      overseaMarketSelectorOptions,
      overseaLocationsSelectorOptions,
      validationSchema
    };
  },
  methods: {
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    }
  }
});
</script>
