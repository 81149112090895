
/* eslint-disable */
import { ref, reactive, onMounted, provide, computed } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";
import router from "@/router";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import InputBox, { InputBoxOptions } from "@/cloudfun/components/InputBox.vue";
import CfInputBox from "@/cloudfun/components/CfInputBoxHorizontal.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";

export default defineComponent({
  components: {
    // Grid,
    InputBox,
    FileUploader,
    CfInputBox,
    VForm: Form
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const imageUrl = ref<any>({});
    const uploader = ref<any>({});
    const uploadAction = `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`;
    const user = JSON.parse(sessionStorage.getItem("EmptyNext-user")!);
    const headers = {
      Authorization: `Bearer ${user?.token}`
    };
    const imageData = { ProductID: 0 };

    const vendorModal = reactive({
      formData: <any>{
        // 	 "taxID": "string",
        //   "name": "string",
        //   "name_Eng": "string",
        //   "address": "string",
        //   "address_Eng": "string",
        //   "setup_Date": "string",
        //   "capital_Stock_Amount": "string",
        //   "paid_In_Capital_Amount": "string",
        //   "contact_Email": "string",
        //   "contact_Tel": "string",
        //   "contact_Title": "string",
        //   "position": 0
      },
      async sendSave() {
        debugger;
        await await model
          ?.dispatch("vendor/save", vendorModal.formData)
          .then(response => {
            if (response.status) {
              CloudFun.send("info", {
                subject: "儲存完成",
                content: "修改成功"
              });
              //   setTimeout(() => {
              //     router.push("/company");
              //   }, 1500);
              close();
            } else {
              CloudFun.send("error", {
                subject: "儲存失敗",
                content: response.message
              });
            }
          })
          .catch(error => {
            CloudFun.send("error", {
              subject: "儲存失敗",
              content: error.message
            });
          });
      },
      async formValid() {}
    });

    const service_Category = ref<any>([]);

    const servicesSelectorOptions: InputBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "請輸入服務類別",
      textField: "name",
      valueField: "id",
      addIfNotExists: true,
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            resolve(service_Category.value);
          }), // eslint-disable-line
        query: params => new Promise((resolve, reject) => {}),
        insert: value => {
          var data = value.name;
          return new Promise((resolve, reject) => {
            resolve({ id: data, name: data });
          });
        }
      }
    };

    const categorySelectorOptions: InputBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "請輸入產品分類",
      textField: "name",
      valueField: "id",
      addIfNotExists: true,
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            resolve(vendorModal.formData.product_Category);
          }), // eslint-disable-line
        query: params => new Promise((resolve, reject) => {}),
        insert: value => {
          var data = value.name;
          return new Promise((resolve, reject) => {
            resolve({ id: data, name: data });
          });
        }
      }
    };

    const overseaLocationsSelectorOptions: InputBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "請輸入海外據點",
      textField: "name",
      valueField: "id",
      addIfNotExists: true,
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            resolve(vendorModal.formData.oversea_Locations);
          }), // eslint-disable-line
        query: params => new Promise((resolve, reject) => {}),
        insert: value => {
          var data = value.name;
          return new Promise((resolve, reject) => {
            resolve({ id: data, name: data });
          });
        }
      }
    };

    const overseaMarketSelectorOptions: InputBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "請輸入海外市場",
      textField: "name",
      valueField: "id",
      addIfNotExists: true,
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            resolve(vendorModal.formData.oversea_Market);
          }), // eslint-disable-line
        query: params => new Promise((resolve, reject) => {}),
        insert: value => {
          var data = value.name;
          return new Promise((resolve, reject) => {
            resolve({ id: data, name: data });
          });
        }
      }
    };

    const targetMarketSelectorOptions: InputBoxOptions = {
      rowId: "id",
      transfer: true,
      placeholder: "請輸入目標市場地區",
      textField: "name",
      valueField: "id",
      addIfNotExists: true,
      columns: [
        {
          field: "name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: value =>
          new Promise((resolve, reject) => {
            resolve(vendorModal.formData.target_Market);
          }), // eslint-disable-line
        query: params => new Promise((resolve, reject) => {}),
        insert: value => {
          var data = value.name;
          return new Promise((resolve, reject) => {
            resolve({ id: data, name: data });
          });
        }
      }
    };

    const getAuth = () => {
      return model
        ?.dispatch("user/auth")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getVendor = () => {
      return model
        ?.dispatch("member/vendor")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const auth = ref<any>({});
    const vendor = ref<any>({});

    onMounted(async () => {
      auth.value = await getAuth();
      if (!auth.value.isBuyer) router.push("/");
      if (auth.value.isVendor) {
        vendor.value = await getVendor();
        if (vendor.value) {
          Object.assign(vendorModal.formData, vendor.value);

          if (vendor.value.logo_ArchiveID)
            imageUrl.value = `${uploadAction}/${vendor.value.logo_ArchiveID}`;
          if (vendor.value.service_Category)
            service_Category.value = vendor.value.service_Category;
        }
      } else {
        router.push("/");
      }
    });

    const phoneExp = new RegExp(
      "^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$"
    );

    const validationSchema = Yup.object().shape({
      // profile: Yup.string().required("必要欄位"),
      // description: Yup.string().required("必要欄位"),
      // tel: Yup.string().matches(phoneExp, '公司電話格式錯誤').required("必要欄位"),
    });

    const close = () => {
      emit("setPage", "info");
    };

    return {
      categorySelectorOptions,
      servicesSelectorOptions,
      vendorModal,
      uploader,
      uploadAction,
      headers,
      imageData,
      imageUrl,
      targetMarketSelectorOptions,
      overseaMarketSelectorOptions,
      overseaLocationsSelectorOptions,
      validationSchema,
      close
    };
  },
  methods: {
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    }
  }
});
